const DEBUG = false; // always set to false!

if (typeof window !== 'undefined') {
  window._paq = window._paq || [];
  _paq.push(['MediaAnalytics::removePlayer', 'vimeo']);
  _paq.push(['MediaAnalytics::removePlayer', 'youtube']);
  _paq.push(['MediaAnalytics::removePlayer', 'html5']);
}

export const dataLayerPush = (layer) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (DEBUG) {
    console.log('[DATALAYER PUSH]: ', layer);
  }

  window._mtm = window._mtm || [];
  window._mtm.push(layer);
};

export function paqTrackEvent(category, action, label) {
  if (typeof window === 'undefined') {
    return;
  }
  window._paq = window._paq || [];
  window._paq.push(['trackEvent', category, action, label]);
}

export const trackEvent = (event, category, action, label, dimensions = {}) => {
  dataLayerPush({
    event,
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    ...dimensions,
  });
};

export const trackHeroCarouselSlideImpression = (slideNumber) => {
  dataLayerPush({
    event: 'slideImpression',
    slideNumber,
  });
};

export const trackHeroCarouselSlideSelect = (slideNumber) => {
  dataLayerPush({
    event: 'slideInteraction',
    slideNumber,
  });
};

export const trackHeroCarouselCtaClick = (clickText) => {
  dataLayerPush({
    event: 'slideCTA',
    clickText,
  });
};

export const trackThreePageViews = () => {
  dataLayerPush({
    event: 'threePageViews',
  });
};

export const trackShuffle = () => {
  dataLayerPush({
    event: 'shuffle',
  });
};

export const trackCarouselNextArrow = () => {
  dataLayerPush({
    event: 'carouselArrow',
  });
};

export const trackReadMore = (headline, module) => {
  dataLayerPush({
    event: 'readMore',
    headline,
    module,
  });
};

export const trackMenuInteraction = (level, clickText) => {
  dataLayerPush({
    event: 'menuInteraction',
    menyLevel: `level ${level}`,
    clickText: clickText.toLowerCase(),
  });
};

export const trackMenuContact = () => {
  dataLayerPush({
    event: 'contactMenu',
  });
};

export const trackContactLead = (module) => {
  dataLayerPush({
    event: 'contactLeadButton',
    module: module.toLowerCase(),
  });
};

export const trackContactForm = (topic) => {
  dataLayerPush({
    event: 'contactForm',
    contactTopic: topic,
  });
};

export const trackContactInteraction = (type) => {
  dataLayerPush({
    event: 'contactInteraction',
    contactType: type,
  });
};

export const trackSharePlatform = (sharePlatform) => {
  dataLayerPush({
    event: 'sharePlatform',
    sharePlatform,
  });
};

export const trackAccordion = (faqQuestion) => {
  dataLayerPush({
    event: 'faqClick',
    faqQuestion,
  });
}

export const pageView = (url, title) => {
  window._paq = window._paq || [];
  window._mtm = window._mtm || [];
  window._paq.push(['setCustomUrl', url]);
  window._paq.push(['setDocumentTitle', title]);
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  window._mtm.push({ event: 'mtm.PageView' });
};
